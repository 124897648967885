import styled, { css } from "styled-components";

export const UploadVideoContainer = styled.div``;

export const UploadTabsWrapper = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: -16px;
  left: 0;
  z-index: 99999;
`;
export const UploadTab = styled.div<{ readonly isActiveTab?: boolean }>`
  flex: 1;
  padding: 12px 0px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  background-color: white;
  color: ${({ isActiveTab, theme }) => (isActiveTab ? theme.colors.seagule : theme.colors.gray2)};
  cursor: pointer;
  position: relative;
  ${({ isActiveTab, theme }) =>
    isActiveTab &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0%;
        width: 100%;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.seagule};
      }
    `}
`;

export const DeleteFile = styled.button`
  border: none;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const PopupSectionTitle = styled.h4<{ readonly noBorder?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.body18};
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  color: ${({ theme }) => theme.colors.nero};
  ${({ noBorder, theme }) =>
    !noBorder &&
    css`
      border-top: 1px solid ${theme.colors.gray5_old};
      padding-top: 16px;
    `};
`;

export const PickColorWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
`;

export const DisplayColor = styled.div<{
  readonly pickedColor?: string;
  readonly isDisabled?: boolean;
}>`
  min-width: 40px;
  min-height: 40px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  background-color: ${({ pickedColor }) => (pickedColor ? pickedColor : "#ffffff")};
  cursor: pointer;
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
        `
      : css`
          opacity: 1;
        `}
`;

export const ColorPickerContainer = styled.div<{ readonly openColorPalltie?: boolean }>`
  transition: all 0.4s ease;
  position: absolute;
  top: ${({ openColorPalltie }) => (openColorPalltie ? "72px" : "0px")};
  opacity: ${({ openColorPalltie }) => (openColorPalltie ? 1 : 0)};
  visibility: ${({ openColorPalltie }) => (openColorPalltie ? "visible" : "hidden")};
  right: 0;
  z-index: 55;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
`;

export const DashboardContainer = styled.div<{ readonly isVideo?: boolean }>`
  padding: ${({ isVideo }) => (isVideo ? "24px 0px 0px" : "0px")};
  .container {
    margin-bottom: 8px;
  }
`;
export const VideoPosition = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  .video-player-style {
    width: 100%;
    iframe {
      width: 100%;
      border-radius: 10px;
    }
  }
`;

export const VideoPlaceHolder = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 10px;
`;

export const ErrorTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.h2};
  color: ${({ theme }) => theme.colors.white};
`;

export const ErrorMessage = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  color: ${({ theme }) => theme.colors.white};
`;

export const IconPosition = styled.div`
  position: absolute;
  top: 94px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 55555;
`;

export const Initialization = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: #efefef;
`;

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#A2D4F8";
  }
  if (props.isDragReject) {
    return "#F25151";
  }
  if (props.isFocused) {
    return "#EFEFEF";
  }
  return "#EFEFEF";
};

export const UploadVideoInput = styled.div`
  width: 100%;
  height: 255px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray5_old};
  display: flex;
  justify-content: center;
  border-radius: 10px;
  label {
    width: 100%;
    height: 255px;
    background-color: transparent;
    border: none !important;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    svg {
      display: none;
    }
    div {
      display: none;
    }
  }
`;

export const UploadFileInput = styled(UploadVideoInput)<{
  readonly isImage?: boolean;
  readonly isdropActive?: boolean;
}>`
  height: ${({ isImage }) => (isImage ? " 144px" : " 115px")};
  padding: 20px;
  background-color: ${({ theme, isdropActive }) => (isdropActive ? "#A2D4F8" : theme.colors.gray5_old)};
  label {
    height: ${({ isImage }) => (isImage ? " 125px" : " 95px")};
  }
`;

export const CenterDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
`;

export const FileInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;
export const FileNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
`;

export const SubjectImage = styled.img`
  width: 142px;
  height: 112px;
  border-radius: 8px;
  background-color: white;
`;

export const StyledPTag = styled.p<{
  readonly noMargin?: boolean;
  readonly textEllipsis?: boolean;
  readonly isFile?: boolean;
}>`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray2};
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "12px")};
  ${({ isFile }) =>
    isFile &&
    css`
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
    `}
  span {
    color: ${({ theme }) => theme.colors.seagule};
    text-decoration: underline;
  }
  ${({ textEllipsis }) =>
    textEllipsis &&
    css`
      max-width: 290px;
      white-space: normal;
      overflow-wrap: break-word;
    `}
`;

export const StyledText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray5_old};
`;

export const UploadedTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body18};
  line-height: 32px;
  color: ${({ theme }) => theme.colors.white};
`;

export const UploadedMessage = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  max-width: 264px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray5};
`;

export const VideoProgressContainer = styled.div`
  position: relative;
`;

export const StyledImage = styled.img`
  height: 256px;
  width: 486px;
  border-radius: 10px;
  z-index: 44;
  object-fit: cover;
`;

export const Place = styled.div`
  width: 486px;
  height: 256px;
  background-color: #313131;
  border-radius: 10px;
  z-index: 44;
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  transform: translate(-60px, 50px);
`;

export const VideoProgressMessage = styled.div`
  position: absolute;
  padding: 14px;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 444;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  z-index: 666;
`;

export const StyledProgress = styled.span<{ readonly persantage?: string }>`
  width: 160px;
  height: 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: all 0.3s ease;
  span {
    display: block;
    width: ${({ persantage }) => persantage && persantage};
    height: 16px;
    background: #45aaf2;
    border-radius: 10px;
  }
`;

export const DownloadFileWrapper = styled.div`
  padding: 0px 10px;
  margin: 0px 15px;
  cursor: pointer;
`;

export const ActionIconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
